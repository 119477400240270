import React, { createContext, ReactNode, useContext, useReducer } from "react";

type LoginFormContextType = {
  state: LoginFormProviderState;
  dispatch: React.Dispatch<LoginFormProviderAction>;
};

export const LoginFormContext = createContext<LoginFormContextType | null>(
  null,
);

export const useLoginFormContext = () => {
  const context = useContext(LoginFormContext);

  if (!context) {
    throw new Error("useFormContext must be used within RegisterFormProvider");
  }

  return context;
};

type LoginFormProviderState = {
  email?: string;
  password?: string;
  isLoading?: boolean;
  passwordError?: string;
  emailError?: string;
};

export enum LoginFormActions {
  SET_EMAIL,
  SET_PASSWORD,
  SET_IS_LOADING,
  SET_ERROR_EMAIL,
  SET_ERROR_PASSWORD,
}

export type LoginFormProviderAction =
  | { type: LoginFormActions.SET_EMAIL; payload: string }
  | { type: LoginFormActions.SET_PASSWORD; payload: string }
  | { type: LoginFormActions.SET_IS_LOADING; payload: boolean }
  | { type: LoginFormActions.SET_ERROR_EMAIL; payload: string }
  | { type: LoginFormActions.SET_ERROR_PASSWORD; payload: string };

const loginFormReducer = (
  state: LoginFormProviderState,
  action: LoginFormProviderAction,
) => {
  switch (action.type) {
    case LoginFormActions.SET_EMAIL:
      return { ...state, email: action.payload };
    case LoginFormActions.SET_PASSWORD:
      return { ...state, password: action.payload };
    case LoginFormActions.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case LoginFormActions.SET_ERROR_EMAIL:
      return { ...state, emailError: action.payload };
    case LoginFormActions.SET_ERROR_PASSWORD:
      return { ...state, passwordError: action.payload };
    default:
      return state;
  }
};

const initialState: LoginFormProviderState = {
  email: "",
  isLoading: false,
  password: "",
  passwordError: "",
  emailError: "",
};

export const LoginFormProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(loginFormReducer, initialState);

  return (
    <LoginFormContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </LoginFormContext.Provider>
  );
};
