import React from "react";
import { cn } from "../../../../helpers/cn";

type LoginFormContainerProps = React.ComponentProps<"div">;

export const LoginFormContainer = ({
  className,
  children,
}: LoginFormContainerProps) => {
  return (
    <form
      className={cn(
        "lg:w-96 flex flex-col justify-center items-start ",
        className,
      )}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </form>
  );
};
