import { AuthLayout } from "../components/global/auth/authLayout/AuthLayout";
import { IGetStaticProps } from "../interfaces/next/IGetStaticProps";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import {
  LoginButton,
  LoginForgotPasswordButton,
  LoginFormContainer,
  LoginFormEmailInput,
  LoginFormPasswordInput,
  LoginFormProvider,
  LoginMiddleContainer,
} from "../components/global/auth/login";
import { Pod1umFullLogo } from "../components/global/logo/Pod1umFullLogo";
import {
  AuthHeaderContainer,
  AuthHeaderSubtitle,
  AuthHeaderTitle,
  AuthSignupHelper,
  AuthSignupHelperLink,
} from "../components/global/auth";
import { AuthBottomContainer } from "../components/global/auth/AuthBottomContainer";
import { AppIconButton } from "../components/global/ui/buttons";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useRouter } from "next/router";
import {
  LoginServiceProvider,
  LoginWithAppleButton,
  LoginWithGoogleButton,
} from "../components/global/auth/loginProviderButtons";

export default function Login() {
  const router = useRouter();

  return (
    <AuthLayout>
      <Head>
        <title>Login | Pod1um</title>
      </Head>

      <LoginFormProvider>
        <LoginFormContainer>
          <AppIconButton
            onClick={() => {
              void router.push("/");
            }}
          >
            <ChevronLeftIcon />
          </AppIconButton>

          <Pod1umFullLogo className={"w-40 h-8 lg:w-40 lg:h-8 2xl:w-40 py-6"} />

          <AuthHeaderContainer>
            <AuthHeaderTitle>Welcome to Pod1um</AuthHeaderTitle>
            <AuthHeaderSubtitle>
              The ultimate coaching platform
            </AuthHeaderSubtitle>
          </AuthHeaderContainer>

          <LoginMiddleContainer>
            <LoginFormEmailInput />
            <LoginFormPasswordInput />

            <LoginForgotPasswordButton>
              Forgot Password
            </LoginForgotPasswordButton>
            <LoginButton />
          </LoginMiddleContainer>

          <AuthBottomContainer>
            <LoginServiceProvider>
              <LoginWithGoogleButton />
              <LoginWithAppleButton />
            </LoginServiceProvider>

            <AuthSignupHelper className={""}>
              Don&apos;t have an account?{" "}
              <AuthSignupHelperLink href="/create-account">
                Sign up now
              </AuthSignupHelperLink>
            </AuthSignupHelper>

            <AuthSignupHelper>
              Are you a coach?{" "}
              <AuthSignupHelperLink
                href={`${process.env.NEXT_PUBLIC_COACH_APP}`}
              >
                Sign up here
              </AuthSignupHelperLink>
            </AuthSignupHelper>
          </AuthBottomContainer>
        </LoginFormContainer>
      </LoginFormProvider>
    </AuthLayout>
  );
}

export async function getStaticProps({
  locale,
}: {
  locale: string;
}): Promise<IGetStaticProps> {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "menu", "footer"])),
    },
  };
}
