import { AppPrimaryButton } from "../../ui/buttons";
import { LoginFormActions, useLoginFormContext } from "./LoginFormProvider";
import { ButtonProps } from "@mui/material";
import { cn } from "../../../../helpers/cn";
import { useRouter } from "next/router";
import { useSnackbarContext } from "../../snackbar/SnackbarProvider";
import { userLogin } from "../../../../lib/authentication";
import { ErrorSnackbar } from "../../snackbar";

type LoginButtonProps = ButtonProps;

export const LoginButton = ({ className }: LoginButtonProps) => {
  const { state, dispatch } = useLoginFormContext();
  const router = useRouter();
  const { setSnackbar } = useSnackbarContext();

  const handleLogin = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(state.email)) {
      dispatch({
        type: LoginFormActions.SET_ERROR_EMAIL,
        payload: "Email is not valid",
      });
      return;
    }

    if (state.password.length < 6) {
      dispatch({
        type: LoginFormActions.SET_ERROR_PASSWORD,
        payload: "Password needs to be at least 6",
      });
      return;
    }

    try {
      dispatch({ type: LoginFormActions.SET_IS_LOADING, payload: true });

      await userLogin(state.email, state.password);

      dispatch({ type: LoginFormActions.SET_ERROR_EMAIL, payload: null });
      dispatch({ type: LoginFormActions.SET_ERROR_PASSWORD, payload: null });

      void router.push("/");
    } catch (e) {
      const error = e as Error;
      setSnackbar(<ErrorSnackbar message={error.message} />);
      console.error(e);
    } finally {
      dispatch({ type: LoginFormActions.SET_IS_LOADING, payload: false });
    }
  };

  return (
    <div className={"w-full flex items-stretch mt-10"}>
      <AppPrimaryButton
        disabled={state.isLoading}
        className={cn("w-full", className)}
        onClick={() => void handleLogin()}
        type={"submit"}
      >
        Sign In
      </AppPrimaryButton>
    </div>
  );
};
