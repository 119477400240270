import { AppTextInput } from "../../ui/input";
import { LoginFormActions, useLoginFormContext } from "./LoginFormProvider";
import React from "react";
import { useFulfillInputsFromQuery } from "../../../../hooks/auth";

export const LoginFormEmailInput = () => {
  const { state, dispatch } = useLoginFormContext();

  const handleEmailChange = (email?: string) => {
    dispatch({ type: LoginFormActions.SET_EMAIL, payload: email });
  };

  const handleEmail = (email: string) => {
    dispatch({ type: LoginFormActions.SET_ERROR_EMAIL, payload: null });
    handleEmailChange(email);
  };

  useFulfillInputsFromQuery({
    queryParameter: "email",
    setter: handleEmailChange,
  });

  return (
    <AppTextInput
      placeholder={"athlete@email.com"}
      value={state.email}
      onChange={(e) => handleEmail(e.target.value.toLowerCase())}
      type={"text"}
      label={"Email"}
      error={!!state.emailError}
      helperText={state.emailError}
    />
  );
};
