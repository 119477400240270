import getConfig from "next/config";
import { IGetConfig } from "../../../../interfaces/next/IGetConfig";
import AppleLogin from "react-apple-login";
import { useLoginWithApple } from "../../../../hooks/user/useLoginWithApple";
import { ButtonProps } from "@mui/material";
import { AuthProviderButton } from "../AuthProviderButton";
import { FaApple } from "react-icons/fa";
import { useLoginServiceContext } from "./LoginServiceProvider";

type LoginWithAppleButtonProps = {
  toReload?: boolean;
} & ButtonProps;

const { publicRuntimeConfig } = getConfig() as {
  publicRuntimeConfig: IGetConfig;
};

export const LoginWithAppleButton = ({
  toReload = true,
  className,
}: LoginWithAppleButtonProps) => {
  const { isLoading, setIsLoading } = useLoginServiceContext();

  useLoginWithApple({
    setIsLoading: (value) => {
      setIsLoading(value);
    },
    toReload: toReload,
  });

  return (
    <AppleLogin
      clientId="com.pod1um.signinservice"
      responseType="code id_token"
      responseMode="form_post"
      scope="name email"
      redirectURI={`${publicRuntimeConfig.NEXT_PUBLIC_BASE_URL}/api/apple`}
      render={(render) => (
        <AuthProviderButton
          disabled={isLoading}
          onClick={render.onClick}
          className={className}
          startIcon={<FaApple />}
          sx={{
            backgroundColor: "#000",
            color: "#FFF",
            width: "100%",
            "&:hover": {
              backgroundColor: "#000",
              color: "#FFF",
            },
          }}
        >
          Continue with Apple
        </AuthProviderButton>
      )}
    />
  );
};
