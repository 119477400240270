import { AppTextInput } from "../../ui/input";
import { LoginFormActions, useLoginFormContext } from "./LoginFormProvider";
import React, { useState } from "react";
import { AuthShowPassword } from "../AuthShowPassword";

export const LoginFormPasswordInput = () => {
  const { state, dispatch } = useLoginFormContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handlePasswordChange = (password?: string) =>
    dispatch({
      type: LoginFormActions.SET_PASSWORD,
      payload: password,
    });

  const handlePassword = (password?: string) => {
    dispatch({
      type: LoginFormActions.SET_ERROR_PASSWORD,
      payload: null,
    });

    handlePasswordChange(password);
  };

  return (
    <AppTextInput
      label={"Password"}
      value={state.password}
      onChange={(e) => handlePassword(e.target.value)}
      className={"mt-4"}
      type={showPassword ? "text" : "password"}
      error={!!state.passwordError}
      helperText={state.passwordError}
      endIcon={
        <AuthShowPassword
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      }
    />
  );
};
