import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Typography } from "@mui/material";

type AuthBottomContainerProps = React.ComponentProps<"div">;

export const AuthBottomContainer = ({ children }: AuthBottomContainerProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <>
      <div className="flex items-center justify-between gap-4 text-sm my-6 w-full">
        <div
          style={{
            backgroundColor: colors.outline.outlineVariant,
          }}
          className="w-20 lg:w-24 h-[0.05rem]"
        />

        <Typography
          className={"text-xs lg:text-sm text-center"}
          style={{
            color: colors.surface.onSurface,
          }}
        >
          or continue with
        </Typography>

        <div
          style={{
            backgroundColor: colors.outline.outlineVariant,
          }}
          className="w-20 lg:w-24 h-[0.05rem]"
        />
      </div>
      <div className={"flex flex-col w-full gap-4"}>{children}</div>
    </>
  );
};
