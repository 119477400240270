import { AppTextButton } from "../../ui/buttons";
import { ButtonProps } from "@mui/material";
import { cn } from "../../../../helpers/cn";
import Link from "next/link";

type LoginForgotPasswordButtonProps = ButtonProps;

export const LoginForgotPasswordButton = ({
  children,
  className,
}: LoginForgotPasswordButtonProps) => {
  return (
    <Link
      href={"/forgot-password"}
      className={cn("flex items-center justify-end mt-4 w-[10rem] ml-auto")}
    >
      <AppTextButton className={cn("p-2", className)}>{children}</AppTextButton>
    </Link>
  );
};
