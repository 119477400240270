import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

type LoginServiceProviderType = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const LoginServiceContext =
  createContext<LoginServiceProviderType | null>(null);

export const useLoginServiceContext = () => {
  const context = useContext(LoginServiceContext);

  if (!context) {
    throw new Error(
      "useLoginServiceContext must be used within RegisterFormProvider",
    );
  }

  return context;
};

export const LoginServiceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoginServiceContext.Provider
      value={{ isLoading: isLoading, setIsLoading: setIsLoading }}
    >
      {children}
    </LoginServiceContext.Provider>
  );
};
