import GoogleLogin, { GoogleLoginResponse } from "react-google-login";
import getConfig from "next/config";
import { IGetConfig } from "../../../../interfaces/next/IGetConfig";
import { loginWithMidias } from "../../../../lib/authentication";
import { useRouter } from "next/router";
import { sendEmailToFirstPromoter } from "../../../../lib/firstPromoter/sendEmailToFp";
import { AuthProviderButton } from "../AuthProviderButton";
import { ButtonProps } from "@mui/material";
import { FcGoogle } from "react-icons/fc";
import { useLoginServiceContext } from "./LoginServiceProvider";

const { publicRuntimeConfig } = getConfig() as {
  publicRuntimeConfig: IGetConfig;
};

type LoginWithGoogleButtonProps = {
  toReload?: boolean;
} & ButtonProps;

export const LoginWithGoogleButton = ({
  toReload = false,
  className,
}: LoginWithGoogleButtonProps) => {
  const router = useRouter();
  const { isLoading, setIsLoading } = useLoginServiceContext();

  async function onSignInGoogle(googleUser: GoogleLoginResponse) {
    try {
      if (!googleUser) {
        return;
      }
      setIsLoading(true);

      const user = await loginWithMidias(googleUser.tokenId, "google");
      sendEmailToFirstPromoter(user.email);

      if (toReload) {
        void router.reload();
        return;
      }
      void router.push("/");
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <GoogleLogin
      onSuccess={(user) => void onSignInGoogle(user as GoogleLoginResponse)}
      clientId={`${publicRuntimeConfig.GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
      render={(render) => (
        <AuthProviderButton
          disabled={isLoading}
          onClick={render.onClick}
          className={className}
          startIcon={<FcGoogle />}
          sx={{
            backgroundColor: "#FFF",
            color: "#565656",
            width: "100%",
            opacity: isLoading ? 0.5 : 1,
            "&:hover": {
              backgroundColor: "#FFF",
              color: "#565656",
            },
          }}
        >
          Continue with Google
        </AuthProviderButton>
      )}
    />
  );
};
